<template>
    <div>
        <v-toolbar flat>
            <v-icon left>mdi-account-multiple-check</v-icon>
            <v-toolbar-title>ASIGNAR TAREAS </v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <!-- <v-btn @click="abrirTareaMotivoDialog(null)" color="blue" small dark><v-icon>mdi-plus</v-icon> Crear</v-btn> -->
            <v-col cols="4">
                <v-autocomplete class="mt-4 pt-4" :items="usuarios" item-text="nombres" item-value="user_login_id"></v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-btn @click="asigarUsuario()" small color="green" dark title="Asignar usuario"><v-icon>mdi-account-multiple-check</v-icon></v-btn>
            </v-col>
            <v-col cols="4">
                <v-text-field class="mx-4 pt-4 mt-4" v-model="search" label="Buscar" ></v-text-field>
            </v-col>
            
        </v-toolbar>
        <v-data-table :headers="headers" :items-per-page="itemsPerPage" 
            :items="tareas" class="elevation-1" small
            item-key="work_effort_id" hide-default-footer
            :search="search"
            v-model="selected"
            show-select
            
             
            >
            <!-- <template v-slot:item.opciones="{ item }">
                <v-btn small text @click="borrarTarea(item.work_effort_id)" color="blue" small dark><v-icon small
                        color="red">mdi-delete</v-icon></v-btn>
            </template> -->
            <template v-slot:item.work_effort_id="{ item }">
                <v-btn text small @click="abrirTareaDialog(item.work_effort_id)">
                    {{ item.work_effort_id }}
                </v-btn>
            </template>
            <template v-slot:item.producto="{ item }">{{ JSON.parse(item.producto).join(",") }}</template>
            <template v-slot:item.total_op="{ item }">{{ parseFloat(item.total_op).toFixed(2) }}</template>
            
            <template v-slot:footer>
                <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
                    total-visible="10"></v-pagination>
            </template>
        </v-data-table>

        <!-- DIALOGO MOTTIVO -->
        <v-dialog v-model="dialogoTarea" persistent width="700">
            <v-form ref="formTarea">
                <v-card>

                    <v-toolbar dark color="blue" height="40" flat>
                        <v-icon left>mdi-calendar-check</v-icon>
                        Tarea / Evento
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dialogoTarea = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-row class="mt-1 mx-2 pt-0 mt-0">
                        <v-col md="12" class="py-0 my-0">
                            <v-text-field v-if="crearTarea == false"  :rules="requiredRule" v-model="datosTarea.work_effort_id"
                                label="Nombre"></v-text-field>
                        </v-col>

                        <v-col md="12" class="py-0 my-0">
                            
                           
                            <!-- <v-autocomplete
                                :rules="requiredRule"
                                :items="categorias"
                                item-value="motivo_cat_id"
                                item-text="descripcion"
                                label="Categoria"
                                v-model="datosMotivo.motivo_cat_id"
                            ></v-autocomplete> -->
                        </v-col>
                        <v-col>
                            <v-text-field :rules="requiredRule" v-model="datosTarea.current_status_id" label="Estado"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-toolbar>
                        <v-btn color="primary" small @click="guardarTarea">
                            <v-icon>mdi-content-save</v-icon>Guardar
                        </v-btn>
                        <v-divider vertical class="px-2" inset></v-divider>
                        <v-btn text small @click="dialogoTarea = false">
                            <v-icon>mdi-content-cancel</v-icon>Cancelar
                        </v-btn>
                    </v-toolbar>


                    
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from 'vue';

export default {
    name: "AsignarTareas",
    data: () => ({
        show: false,

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        search: "",
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 0,
        required: [(v) => !!v || "El campo es requerido"],
        
        headers: [
            
            { text: "ID", align: "start", value: "work_effort_id" },
            { text: "Tipo", align: "start", value: "work_effort_type" },
            { text: "Prioridad", align: "start", value: "pri" },
            { text: "Valor", align: "start", value: "total_op" },
            { text: "Desde", align: "start", value: "estimated_start_date" },
            { text: "Asunto", align: "start", value: "work_effort_name" },
            { text: "Fecha ult. factura", align: "start", value: "fecha_ult_fa" },
            { text: "Producto", align: "start", value: "producto" },
            { text: "Teléfono", align: "start", value: "telefono" },                        
            { text: "Whatsaps enviados", align: "start", value: "num_whatsapp" },
            { text: "Cotizacion", align:"start",value: "cotizacion_pendiente"},
            
        ],
        tareas: [],
        usuarios: [],
        dialogoTarea: false,
        datosTarea: {
            work_effort_id: "",
            current_status_id: "",            
            description: "",
            work_effort_name: "",
            estado: ""
        },
        //catalogo: [],
        //categorias: [],
        //acciones:[],
        crearTarea: false,
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
            "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),

        cargarTareas() {
            this.setLoadingTable(true);
            this.setUrl("asignar-tareas-usuario");

            this.requestApi({
                method: "GET",
                data: {
                    page: this.currentPage,
                    page_size: this.itemsPerPage,
                    //paginator: true,
                    //source_type_id: "LEAD_SOURCE",
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.tareas = res.data._embedded.asignar_tareas_usuario;
                    this.pageCount = res.data.page_count;
                    this.currentPage = res.data.page;
                    this.totalItems = res.data.total_items;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },
        cargarDatos() {
            this.setLoadingTable(true);
            this.setUrl("asignar-tareas-usuario");

            this.requestApi({
                method: "POST",
                data: {
                    accion: "datosAsignarTareas",
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.usuarios = res.data.detail.usuarios;
                    
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },
        abrirTareaDialog(Id) {
            this.dialogoTarea  = true;
            this.cargarDatosTarea(Id);
            this.datosTarea = {
                work_effort_id: "",
                work_effort_name: "",
                current_status_id: "",
            };
            this.crearTarea = true;
            if (Id != null) {
                this.crearTarea = false;                                
            }
        },

        cargarDatosTarea(id) {
            this.setLoadingTable(true);
            this.setUrl("asignar-tareas");
            this.requestApi({
                method: "POST",
                data: {accion:"datosTareaUsuario","work_effort_id" : id},
            })
                .then((res) => {
                    
                    if(res.data.detail)
                    this.datosTarea = res.data.detail;
                    //this.categorias = res.data.detail.categorias
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        asigarUsuario(){
            this.setLoadingTable(true);
            this.setUrl("asignar-tareas");
            this.requestApi({
                method: "POST",
                data: {
                    accion:"asignarUsuarios",
                    tareas : selected,
                    usuario_id: this.usuarioId
            },
            })
                .then((res) => {
                    
                    if(res.data.detail)
                    this.datosTarea = res.data.detail;
                    
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },
        borrarTarea(Id) {

            Vue.swal({
                html: "Está seguro de eliminar esta tarea ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                this.setLoadingTable(true);
                this.setUrl("tareas-usuario");
                this.requestApi({
                    method: "POST",
                    data: {
                        accion: "borrarTarea",
                        work_effort_id: Id, 
                    },
                })
                    .then((res) => {
                        //console.log(res.data);
                        this.cargarDatos();
                        this.dialogoTarea = false;
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
            });
        },
        handlePageChange(value) {
            
            this.cargarDatos();
        },
    },
    mounted() {
        this.cargarDatos();
        this.cargarTareas();
        this.setTitleToolbar("ASIGNAR TAREAS");
    },
};
</script>
